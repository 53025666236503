import { defer } from "react-router-dom";

export async function loadMatchup() {
  const matchup = window
    .fetch(`/api/v1/matchup`, { method: "POST", body: JSON.stringify({}) })
    .then((resp) => resp.json());

  return defer({
    matchup: matchup,
  });
}

export const postMatchupAction = async ({
  matchupId,
  winnerId,
}: {
  matchupId: string;
  winnerId: string;
}) => {
  const payload = {
    previous: {
      matchupId: matchupId,
      winnerId: winnerId,
    },
  };

  const resp = await window.fetch("/api/v1/matchup", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  return await resp.json();
};

export async function loadStats() {
  const stats = window
    .fetch(`/api/v1/stats`, { method: "GET" })
    .then((resp) => resp.json());

  return defer({ stats: stats });
}
