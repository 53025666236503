export default function ErrorDisplay() {
  return (
    <div>
      <h1>Oh no! Something went wrong. 💀</h1>
      <h1>Try refreshing the page as quickly as you can. 💨</h1>
      <h1>
        If that doesn't work, try stepping outside and shaking your fist at the
        sun. 🌞
      </h1>
    </div>
  );
}
