import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import phyrexianMana from "../assets/img/manabp.png";

type FlagCodeProps = {
  flagCode: string | null;
  language: string | null;
};

export function Flag({ flagCode = null, language = null }: FlagCodeProps) {
  return (
    (flagCode &&
      flagCode !== "PH" &&
      flagCode !== "GLOBAL" &&
      hasFlag(flagCode) && (
        <span className="ml-[5px]" title={language || ""}>
          {getUnicodeFlagIcon(flagCode)}
        </span>
      )) ||
    (flagCode === "PH" && (
      <img
        className="Flag"
        src={phyrexianMana}
        title="ph"
        alt="Phyrexian Mana Symbol, so spooky"
      />
    )) ||
    (flagCode === "GLOBAL" && (
      <span className="Flag" title={flagCode}>
        🌍
      </span>
    )) ||
    null
  );
}
