import {
  Stats,
  MatchupCard as ApiCard,
  CardRankings,
  MatchupCard,
} from "../api/types";

import { useState } from "react";
import { BarChart, Bar, Cell, ResponsiveContainer, YAxis } from "recharts";
import { Card } from "../card/Card";

export function GraphView({ stats, color }: { stats: Stats; color: string }) {
  // TODO consider how best to DRY this up
  function cards(colorIdentity: string): ApiCard[] {
    const colorStatistics = stats.stats.find(
      (es) => es.color === colorIdentity,
    );
    if (!colorStatistics) {
      throw new Error("die!");
    }

    const ids = colorStatistics.scores.map((s) => s.id);

    return ids.map((id) => {
      const cardById = stats.cards.find((c) => c.id === id);
      if (!cardById) {
        // backend's fault for providing a card id but not its metadata
        throw new Error("die!");
      }
      return cardById;
    });
  }

  const ranks = stats.stats.find((s) => s.color === color);
  if (!ranks) {
    // backend's fault for not having the right card ranks on hand
    throw new Error("die!");
  }

  return (
    <>
      <Graph ranks={ranks} cards={cards(color)} />
    </>
  );
}

function Graph({
  ranks,
  cards,
}: {
  ranks: CardRankings;
  cards: MatchupCard[];
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const data = ranks.scores.map((s) => {
    let card = cards.find((c) => c.id === s.id);
    if (!card) {
      throw new Error("die!");
    }

    return Object.create({
      score: s.score,
      id: s.id,
      name: card.name,
      artist: card.artist,
      set: card.set,
      collectorNumber: card.collectorNumber,
      imageUrl: card.imageUrl,
    });
  });

  let activeItem = data[activeIndex] || data[0];

  return (
    <div className="w-[calc(min(75%,800px))]">
      <ResponsiveContainer width="100%" height={100}>
        <BarChart width={150} height={40} data={data}>
          <YAxis type="number" domain={["dataMin-0.01", "dataMax"]} hide />
          <Bar
            dataKey="score"
            onDragEnter={(_, index) => setActiveIndex(index)}
            onMouseMove={(_, index) => setActiveIndex(index)}
            background={{ fill: "transparent" }}
          >
            {data.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={index === activeIndex ? "#d2cfff" : "#cbafb6"}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Card
        onImageLoad={() => undefined}
        key={activeItem.id}
        card={activeItem}
      />
    </div>
  );
}
