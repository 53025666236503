import "./Card.css";
import { MatchupCard as ApiCard } from "../api/types";
import { Flag } from "../Flag";

type CardProps = {
  card: ApiCard;
  onImageLoad?: () => void;
};

export function Card({ card, onImageLoad = () => {} }: CardProps) {
  const flagCode = card.flagCode;
  const language = card.language;

  const tooltip = `${card.set} – #${card.collectorNumber}`;

  return (
    <div>
      <img
        className="peer/image pointer-events-none inline w-[87%] rounded-b-[4.5%] rounded-t-[4.75%]"
        onLoad={onImageLoad}
        src={card.imageUrl}
        alt={tooltip}
      />
      <div className="hidden">
        {/* <div className="hidden peer-hover/image:block hover:block"> */}
        <div data-tooltip-id={card.id + "-detail"}>
          <span className="Card-data-glyph">ⓘ</span>
          <span>{card.artist}</span>
        </div>
        <Flag flagCode={flagCode} language={language} />
      </div>
    </div>
  );
}
