import { Await, useLoaderData } from "react-router-dom";
import { Matchup } from "../components/api/types";
import { MatchupGallery } from "../components/matchup/MatchupGallery";
import React from "react";
import { LoadingBlocker } from "../components/LoadingBlocker";
import ErrorDisplay from "../components/ErrorDisplay";

export function MatchupRoute() {
  const data = useLoaderData() as { matchup: Matchup };

  return (
    <React.Suspense fallback={<LoadingBlocker />}>
      <Await resolve={data.matchup} errorElement={<ErrorDisplay />}>
        {(matchup) => <MatchupGallery initialMatchup={matchup} />}
      </Await>
    </React.Suspense>
  );
}
