import construction from "../assets/img/construction.gif";

import styled from "styled-components";

const FooterLink = styled.a`
  color: gray;
  text-decoration: underline;
`;

const Footer = () => (
  <footer
    className={`bottom-0 left-0 mx-0 flex w-[100%] flex-row-reverse place-items-center gap-2 border-t-8 border-secondary bg-white p-6 text-left font-sans text-tiny text-gray`}
  >
    <p>
      Wizards of the Coast, Magic: The Gathering, and their logos are trademarks
      of Wizards of the Coast LLC in the United States and other countries. ©
      1993-{new Date().getFullYear()} Wizards. All Rights Reserved.
      <br />
      <br />
      <FooterLink href="/">bestbasicland.com</FooterLink> and its proprietors
      are not affiliated with, endorsed, sponsored, or specifically approved by
      Wizards of the Coast LLC.{" "}
      <FooterLink href="/">bestbasicland.com</FooterLink> and its proprietors
      may use the trademarks and other intellectual property of Wizards of the
      Coast LLC, which is permitted under Wizards' Fan Site Policy. MAGIC: THE
      GATHERING® is a trademark of Wizards of the Coast. For more information
      about Wizards of the Coast or any of Wizards' trademarks or other
      intellectual property, please visit{" "}
      <FooterLink href="https://company.wizards.com">their website</FooterLink>.
    </p>
    <img
      src={construction}
      alt="Under construction (since 2018)!"
      className="hidden [transform:rotateY(180deg)] lg:block"
    />
  </footer>
);

export default Footer;
