import { Link, Outlet, useNavigate } from "react-router-dom";
import { Ad } from "./components/Ad";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NextUIProvider,
} from "@nextui-org/react";
import { ReactComponent as Logo } from "./assets/img/logo.svg";
import Footer from "./components/Footer";

type AppProps = {
  mordMode: boolean;
};

function App({ mordMode }: AppProps) {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate}>
      <div className="flex flex-col items-center justify-center bg-primary text-center">
        <div className="z-1 min-h-screen w-[100%] bg-primary">
          <header>
            <Navbar position="static" className="bg-primary">
              <Link to="/">
                <NavbarBrand className="space-x-2">
                  <Logo className="h-[50px] w-[50px] select-none" />
                  <p>bestbasicland.com</p>
                </NavbarBrand>
              </Link>
              <NavbarContent justify="center">
                <NavbarItem>
                  <Link to="/stats">Stats</Link>
                </NavbarItem>
              </NavbarContent>
            </Navbar>
            <Ad useMord={mordMode} />
          </header>
          <main className="mt-8">
            <Outlet />
          </main>
        </div>
        <Footer />
      </div>
    </NextUIProvider>
  );
}

export default App;
