import "./Ad.css";
import gatewatch from "../assets/img/ad.jpeg";
import mord from "../assets/img/mord.png";
import { useEffect, useRef } from "react";

type AdProps = {
  useMord: boolean;
};

export function Ad({ useMord }: AdProps) {
  // An ad component that wraps a sticky ad image. The ad starts at the top of the page and sticks to the top of the page when the user scrolls past it.
  const adRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ad = adRef.current;
    if (!ad) return;

    const handleScroll: EventListener = () => {
      if (window.scrollY > ad.offsetTop) {
        ad.classList.add("Ad-sticky");
      } else {
        ad.classList.remove("Ad-sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const imageProps = useMord
    ? {
        src: mord,
        alt: "Mord",
      }
    : {
        src: gatewatch,
        alt: "Gatewatch",
      };

  return (
    <div ref={adRef}>
      <img
        className={`inline max-w-[calc(min(40%,400px))] border-4 border-solid border-secondary`}
        src={imageProps.src}
        alt={imageProps.alt}
      />
    </div>
  );
}
