import { Matchup } from "../../components/api/types";
import { Card } from "../../components/card/Card";
import { useState } from "react";
import { postMatchupAction } from "../../components/api/api";
import { LoadingBlocker } from "../LoadingBlocker";
import ErrorDisplay from "../ErrorDisplay";
import { Button } from "@nextui-org/react";

export function MatchupGallery({
  initialMatchup,
}: {
  initialMatchup: Matchup;
}) {
  const [matchup, setMatchup] = useState(initialMatchup);
  const [isMatchupLoading, setLoading] = useState(false);
  const [imageLoadingStatuses, setImageLoadingStatuses] = useState(
    initialMatchup.cards.map(() => false),
  );
  const [isError, setError] = useState(false);

  const handleImageLoad = (index: number) => {
    setImageLoadingStatuses((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  const allImagesLoaded = imageLoadingStatuses.every(Boolean);

  async function submitMatchup(winnerId: string, matchupId: string) {
    setLoading(true);
    try {
      const resp = await postMatchupAction({ winnerId, matchupId });
      const matchup = resp as Matchup;
      setMatchup(matchup);
      setImageLoadingStatuses(matchup.cards.map(() => false));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  if (isError) {
    return <ErrorDisplay />;
  } else {
    return (
      <>
        {(isMatchupLoading || !allImagesLoaded) && <LoadingBlocker />}
        <div
          className="Card-gallery"
          style={{
            display: !isMatchupLoading && allImagesLoaded ? "flex" : "none",
          }}
        >
          {matchup.cards.map((card, index) => (
            <div key={card.id} className="mb-8">
              <Card
                onImageLoad={() => handleImageLoad(index)}
                key={card.id}
                card={card}
              />
              <Button
                size="lg"
                className="mt-4 bg-black text-white"
                onClick={(e) => submitMatchup(card.id, matchup.id)}
              >
                Pick
              </Button>
            </div>
          ))}
        </div>
      </>
    );
  }
}
