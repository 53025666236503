import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MatchupRoute } from "./routes/MatchupRoute";
import { loadMatchup, loadStats } from "./components/api/api";
import { StatsRoute, StatsView } from "./routes/Stats";

const imGoingMordMode = Math.random() > 0.5;

const router = createBrowserRouter([
  {
    path: "/",
    element: <App mordMode={imGoingMordMode} />,
    // errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MatchupRoute />,
        loader: loadMatchup,
      },
      {
        path: "/stats",
        element: <StatsRoute initialView={StatsView.Graph} />,
        loader: loadStats,
        shouldRevalidate: (_) => false,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
